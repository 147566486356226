/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <Column className="--menu pb--0 pt--0" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(199,18,18,1)"}} menu={true} border={null}>
        </Column>


        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--10 pl--25 pr--25 pt--10" name={"fotky-my"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Title className="title-box ff--3 mt--06" style={{"maxWidth":620,"marginTop":null}} content={"<b><b>O nás </b><br></b>"}>
              </Title>

              <Text className="text-box ff--3 fs--22 lh--12" style={{"maxWidth":800}} content={"ORCA DIVING, s.r.o. je potápěčské centrum, které vzniklo 1. 1. 2000. Zajišťujeme potápěčské kurzy, prodej a servis rekreačního i specializovaného potápěčského vybavení, výjezdy na české i zahraniční lomy, dopravu do zámořských potápěčských destinací a ubytování u lokalit. Dále provozujeme plnírnu a půjčovnu výstroje a pořádáme tematické přednášky."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"marginBottom":0,"paddingBottom":43.850006103515625}} name={"51pz8zygrgi"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/88b5279c6ea04662aa7f41854e4f6b90_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/88b5279c6ea04662aa7f41854e4f6b90_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/88b5279c6ea04662aa7f41854e4f6b90_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/88b5279c6ea04662aa7f41854e4f6b90_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/88b5279c6ea04662aa7f41854e4f6b90_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Anna Hutňanová<br>"}>
              </Text>

              <Text className="text-box ff--3 w--400" content={"• majitelka, jednatelka\n<br>• ASTD Sport Diving Instructor, Cave diver<br>• stará se o chod Orky, stránky a FB<br>• výpomoc při kurzech&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/db6fcb49562b4ca289df5d3d94fd423f_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/db6fcb49562b4ca289df5d3d94fd423f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/db6fcb49562b4ca289df5d3d94fd423f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/db6fcb49562b4ca289df5d3d94fd423f_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Jaroslava Císařová<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• P**\n<br>• má na starosti provoz obchodu, půjčovny a hladký průběh kurzů<br>• předsedkyně klubu Orca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/e0d0641c4a6246ab97849f69bed91e88_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/e0d0641c4a6246ab97849f69bed91e88_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e0d0641c4a6246ab97849f69bed91e88_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Tomáš Burian<b><br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"• Instruktor IANTD\n<br>• ASTD Technical Cave Diving Instructor<br>• ASTD Technical Nitrox Instructor<br>• kurzy Deep diver, AEANx, Divemaster, školitel na dvojče, sidemount a stage\n<br>• servis potápěčské techniky<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/o/13078/62ec9882c56546adac7d5f5149385be6.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"<b>Milan Kotva<br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"• Instruktor CMAS I**<br>• v Orce má na starosti výuku a výcvik začátečníků, P***, basic nitrox a potápění pod ledem<br>• kurzy pro děti<br>• servis potápěčské techniky"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/9cf00f7a5b514ca08abf86cbf39a450c_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/9cf00f7a5b514ca08abf86cbf39a450c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/9cf00f7a5b514ca08abf86cbf39a450c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/9cf00f7a5b514ca08abf86cbf39a450c_s=860x_.jpg 860w"} position={{"x":"-0.7692307692307693%","y":"-88.76923076923077%"}}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Tomáš Lehmann<b><br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"• ASTD&nbsp;Technical Cave Diving Instructor<br>• ASTD Technical Nitrox Instructor<br>• IANTD Divemaster<br>• průvodce mexickými cenoty<br>• kurz potápěčský zdravotník&nbsp;<br>• servis potápěčské techniky<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/c38baf47a6374b0f926a448cf5087293_s=350x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/c38baf47a6374b0f926a448cf5087293_s=350x_.jpeg 350w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Aleš Veselý<b><br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"• Instruktor CMAS**&nbsp;<br>• Basic Nitrox Instruktor<br>• Instruktor dětských potápěčů<br>• Cave a Trimix diver<br>• Kurzy AOWD a P**<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/c3fba943a4dc4bc0b614e8aead1c235c_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/c3fba943a4dc4bc0b614e8aead1c235c_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"<b>Ivo Havránek<br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"<span style=\"font-family: Muli, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.32px;\">• Instruktor trenér I</span><span style=\"font-size: 16px; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-family: Muli, sans-serif; letter-spacing: -0.32px;\">ANTD</span><span style=\"font-family: Muli, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.32px;\"><br></span>• Normoxic trimix a&nbsp;<span style=\"font-family: Muli, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.32px;\">Technical diver Instruktor&nbsp;</span><br>• vede kurzy Technical a Normoxic diver<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/o/13078/7747655938c941638a2cc7d9b7318cb9.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"<b>Štěpán Krejcar<br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"• Instruktor IANTD<br>• Instruktor potápění se sidemountem<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/6f9e01576154459b81ab5711074b6407_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/6f9e01576154459b81ab5711074b6407_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/6f9e01576154459b81ab5711074b6407_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/6f9e01576154459b81ab5711074b6407_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"<b>Martin Hutňan<br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"• divemaster\n<br>• tichý společník, rádce a dozor"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/50e77827a94f4b168337263266ff3147_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/50e77827a94f4b168337263266ff3147_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"<b>Daniel Hutňan<br></b>"}>
              </Text>

              <Text className="text-box ff--3" content={"Majitel firmy od jejího založení do roku 2020.\nTech. Cave Diving Instructor Trainer IANTD, Cave Diving Instruktor CMAS I***, Advanced Nitrox Instructor, aj. \nByl členem České speleologické společnosti a předsedou klubu Speleoaquanaut. Čtyřikrát získal cenu za největší objev v zahraničí (Mexiko, Sardinie). Byl organizátorem více než dvaceti expedic do Mexika a na Sardinii. Spoluobjevil a zmapoval přes 130 kilometrů nových jeskynních prostor. Zemřel 26. 6. 2020."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"61w9q89euln"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":525}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":496}}>
              
              <Title className="title-box title-box--center ff--3 fs--24" style={{"maxWidth":619}} content={"Divemasteři a technici<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":30,"paddingBottom":25}} name={"b53ldkxx0xq"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" style={{"maxWidth":1149}} columns={"4"} fullscreen={true}>
            
            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/o/13078/35c034a111464b1db63225ed1cecbf94.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={""} position={{"x":"-5.909090909090908%","y":"0%"}}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Zuzka Krejcarová<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• v přípravě na divemastera<br>• pravá ruka instruktorů v bazénu<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/7052d368ee5240b98fa368e599df1aae_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/7052d368ee5240b98fa368e599df1aae_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7052d368ee5240b98fa368e599df1aae_s=660x_.jpg 660w"} position={{"x":"-5.909090909090908%","y":"0%"}}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Aneta Braun<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• divemaster<br>• pravá ruka instruktorů v bazénu<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/o/13078/8fe92be256ed4402a3a7d94835a29b00.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={""}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Jasmína Plšková<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• v přípravě na divemastera<br>• pravá ruka instruktorů v bazénu<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/6730675e15564047867770f093f54b10_s=350x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/6730675e15564047867770f093f54b10_s=350x_.JPG 350w"} position={{"x":"-5.909090909090908%","y":"0%"}}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Pavel Šebesta<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• divemaster\n<br>• Zájezd Marsa Shagra&nbsp;<br>• servis potápěčské techniky<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/42288a010c614d73ba51644b958459f5_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/42288a010c614d73ba51644b958459f5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/42288a010c614d73ba51644b958459f5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/42288a010c614d73ba51644b958459f5_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Karel Plšek<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• divemaster v přípravě na CMAS I**<br>• zájezd Estartit&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/2a85a2cb74bb419487c7996ba3cc0edc_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/2a85a2cb74bb419487c7996ba3cc0edc_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"David Krejčí<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"&nbsp;• divemaster\n<br>• Má na starosti zájezd na francouzské vraky a do chorvatského Pakoštane&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/10f856ba68c74106b27d338c31d44601_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/10f856ba68c74106b27d338c31d44601_s=350x_.jpg 350w"} position={{"x":"-5.909090909090908%","y":"0%"}}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Ondra Vodička<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"•  divemaster<br>• pravá ruka instruktorů v bazénu<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/ee20afc0a8a348f599d7e840096b2c39_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/ee20afc0a8a348f599d7e840096b2c39_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ee20afc0a8a348f599d7e840096b2c39_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ee20afc0a8a348f599d7e840096b2c39_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Radim Vácha<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• divemaster\n<br>• kurz podvodní fotografie&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/5c3e7b6e80ed438ea246c7fc48f5c786_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/5c3e7b6e80ed438ea246c7fc48f5c786_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Miroslav Behúň<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• divemaster<br>• servis potápěčské techniky<br>• servis suchých obleků<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":220}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/48d54f9d31cb45299fb26091a6477a97_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 25vw" srcSet={"https://cdn.swbpg.com/t/13078/48d54f9d31cb45299fb26091a6477a97_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/48d54f9d31cb45299fb26091a6477a97_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/48d54f9d31cb45299fb26091a6477a97_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/48d54f9d31cb45299fb26091a6477a97_s=1400x_.jpeg 1400w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Milan Kotva ml.<br>"}>
              </Text>

              <Text className="text-box ff--3" content={"• divemaster&nbsp;v přípravě na CMAS I**<br>• kurzy ve znakovém jazyce<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"sx0zzw42xyl"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":13}} name={"9u9ak48q93n"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3 fs--24" content={"Poděkování"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":5}} name={"17nks2nn1cp"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/9fcd3438e076436d8c7b3e9b799af492_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/9fcd3438e076436d8c7b3e9b799af492_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box ff--3 w--600" content={"Jaroslav Tošner<br>"}>
              </Text>

              <Text className="text-box ff--3 fs--16" content={"• účetní, rádce, přítel"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":-1028,"paddingLeft":0,"paddingBottom":0}}>
              
              <Text className="text-box fs--16" style={{"marginTop":122}} content={"Děkujeme Jardovi za výdrž, jakou měl s Orkou od jejích začátků, v dobrém i zlém, za pečlivost, trpělivost a cenné rady.<br><br>Tým Orca<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":-1028,"paddingLeft":0}}>
              
              <Image className="--shape5" style={{"maxWidth":260}} src={"https://cdn.swbpg.com/t/13078/071bb79ee4a645d9a069a6c44f036268_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/071bb79ee4a645d9a069a6c44f036268_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Jiří Hovorka</span>"}>
              </Text>

              <Text className="text-box" content={"• Instruktor IANTD, instruktor CMAS I***\n<br>• školitel potápěčů i instruktorů všech stupňů\n<br>• certifikovaný servisní technik Apeks, <br>Tecline, Atomic a Poseidon"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":-1028,"paddingLeft":0,"paddingBottom":0}}>
              
              <Text className="text-box" style={{"marginTop":122}} content={"Děkujeme Jirkovi za jeho dlouholeté působení v Orce a přejeme mu do dalších let mnoho podvodních i suchozemských zážitků.<br><br>Tým Orca<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}